/*!

 =========================================================
 * CoMMarkaStudios (AeroFarallones) - v1.0.0
 =========================================================

 * Product Page: https://www.commarka.app
 * Copyright 2023 CoMMArka Studios (https://www.commarka.app)

 * Designed by www.commarka.app (Marlon Garcia - Angelo Acevedo - Julian Ramirez)
 * Coded by www.commarka.app (Marlon Garcia - Julian Ramirez - Martin Sierra)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import '~bootstrap/scss/bootstrap';
